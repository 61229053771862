@import "src/packages/scss/varriable";

.core-static-wrapper {

  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &.inner-page {
    height: calc(100vh - 120px);
  }

  .core-static-wrapper-content {

    width: 352px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .core-static-image {
      margin-bottom: 60px;
    }

  }

  .core-static-sub-title {
    text-align: center;
    margin: 10px 0 0 0;
  }

  .core-static-navigation {
    margin-top: 24px;
    display: flex;
    gap: 12px;
  }

  .core-maintenance-image {
    margin-bottom: 60px;
  }

}

