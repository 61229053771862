@import '../../../../packages/scss/varriable';

.organization-setup-layout-wrapper {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $gray-color-200;
    padding: 15px 30px;

    .button-area{
      display: flex;
      align-items: center;
      gap: 30px;
    }

  }
}
