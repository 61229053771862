@import "src/packages/scss/varriable";

.ksa-upload-wrapper {
  .ant-upload-list-item-action {
    opacity: 1 !important;
  }

  .ant-upload-wrapper .ant-upload-drag {
    border: 1px solid $gray-color-200;
    border-radius: $border-radius-4;
  }

  .ant-upload-wrapper .ant-upload-drag .ant-upload {
    padding: 0 !important;
  }

  .ant-upload-drag-container {
    .upload-text {
      font-weight: 600;
      font-size: 14px;
      color: $primary-color;
    }

    .drag-n-drop-text {
      font-weight: 400;
      font-size: 14px;
      color: $gray-color-500;
    }

    .ant-upload-drag-icon {
      .anticon {
        color: $gray-color-500 !important;
      }
    }

  }
}
