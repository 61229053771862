@import '../../../../../packages/scss/varriable';

.download-recovery-codes-wrapper {
    padding: 30px;

    .title-color {
        color: $gray-color-700;
    }

    .tow-fa-sub-title {
        margin-top: 6px;
        margin-bottom: 20px;
        width: 582px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $gray-color-700;
    }

    .two-factor-info-container {
        width: 667px;
        height: 108px;
        background: #fffaeb;
        border-radius: $border-radius-4;
        padding: 18px 18px !important;

        .margin-left-42 {
            margin-left: 42px;
        }
    }

    .info-box {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #7a2e0e;

        .anticon svg {
            display: inline-block;
            width: 20px;
            height: 20px;
        }

        p {
            margin: 0;
        }
    }

    .info-box div:first-child {
        margin-right: 22px;
    }

    .two-factor-pin {
        margin-top: 20px;
        width: 667px;
        background: #f2f4f7;
        border-radius: $border-radius-4;

        .list {
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 12px !important;
            font-weight: 700;
            font-size: 14px;
            color: $gray-color-700;

            li {
                margin: 14px 18px 14px 140px;
            }
        }
    }

    .download-button {
        border: 1.67px solid #ffffff;
        margin-top: 20px;
        margin-left: 530px;
        font-weight: 600;
        font-size: 14px;
        color: #ffffff;

        .anticon svg {
            display: inline-block;
            width: 15px;
            height: 15px;
        }
    }
}
