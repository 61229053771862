.core-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  cursor: pointer;
  position: relative;


  &.large {
    .core-logo-img {
      height: 100px;
      width: auto;
    }
  }


  &.medium {
    .core-logo-img {
      height: 40px;
      width: auto;
    }
  }
  &.small {
    .core-logo-img {
      height: 34px;
      width: auto;
    }
  }


  .env {
    color: #000;
    font-weight: 500;
  }
}