.confirm-signup-wrapper {
  min-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sub-message {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;

  p {
    margin: 2px;
  }
  .ant-spin {
    margin-top: 24px !important;
  }
}