@import "src/packages/scss/varriable";

.core-button {
  cursor: pointer;
  display: inline-block;
  padding: 10px 18px;
  height: 44px;
  font-weight: 600;
  font-size: 16px;
  border-radius: $border-radius-4;
  line-height: 1rem;

  &.ant-btn-text {
    color: $gray-color-500
  }

  &.ant-btn-icon-only {
    width: 44px;
  }

  &.primary {
    border: 1px solid $primary-color;
    background-color: $primary-color;
    color: $white-color;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);

    &:hover {
      border: 1px solid $primary-color;
      color: $white-color !important;
    }
  }


  &.secondary {
    border: 1px solid $primary-color;
    background-color: $white-color;
    color: $primary-color;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);

    &:hover {
      border: 1px solid $primary-color;
      color: $primary-color !important;
    }

  }

  &.basic {
    border: 1px solid $gray-color-300;
    background-color: $white-color;
    color: $gray-color-700;

    &:hover {
      border: 1px solid $gray-color-300;
      color: $gray-color-700 !important;
    }

  }

  &.danger {
    border: 1px solid $error-color;
    background-color: $error-color;
    color: $white-color;

    &:hover {
      border: 1px solid $error-color;
      color: $white-color !important;
    }

  }


  &.primaryHover {
    border: 1px solid $gray-color-300;
    background-color: $white-color;
    color: $gray-color-700;

    &:hover {
      color: $primary-color !important;
      border-color: $primary-color !important;
    }

  }

  &.full-width {
    width: 100%;
  }
}

.primary:disabled {
  background-color: $primary-disable;
  border: 1px solid $primary-disable;
}
