@import '../../../../packages/scss/varriable';

.two-factor-auth-wrapper {
    .ant-steps-item {
        .ant-steps-item-title {
            font-size: 20px;
            font-weight: 600;
        }

        .ant-steps-item-description {
            font-size: 16px;
            font-weight: 500;
            color: $gray-color-500 !important;
        }

        &.ant-steps-item-active {
            .ant-steps-item-description {
                color: $title-color !important;
            }
        }

        &.ant-steps-item-finish {
            .ant-steps-item-title {
                color: $primary-color !important;
            }

            .ant-steps-item-description {
                color: $title-color !important;
            }
        }
    }

    .two-factor-auth-stepper-header {
        padding: 30px;

        .two-factor-auth-stepper-sub-title {
            margin-top: 6px;
            margin-bottom: 0;
            font-size: 16px;
            color: $gray-color-500;
        }
    }

    .two-factor-auth-step-area {
        border-top: 1px solid $gray-color-200;

        .left-steps {
            padding: 30px;
        }

        .two-factor-auth-stepper-content {
            border-left: 1px solid $gray-color-200;
            height: calc(100vh - 232px);
            overflow-y: auto;

            .two-factor-auth-step-button-group {
                display: flex;
                justify-content: flex-end;
                padding: 20px;
                position: relative;
                width: 100%;
                bottom: 0;
                right: 0;
                background-color: $white-color;
                border-top: 1px solid $gray-color-200;

                .core-button {
                    margin-left: 8px;
                }
            }
        }
    }
}
