@import "src/packages/scss/varriable";
.core-input-search {
  position: relative;
  width: 100%;
  .icon {
    position: absolute;
    padding: 16px 16px 12px 12px;
    box-sizing: border-box;
    min-width: 50px;
    text-align: center;
    left: 0;
  }

  .input-field {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    padding-left: 40px;
    border-radius: $border-radius-4;
    height: 44px;
    border: 1px solid $gray-color-300;
    &:hover {
      border-color: $primary-color
    }
    &:focus-visible {
      border: 1px solid $primary-color;
      outline: none;
    }
  }
}
