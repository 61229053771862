@import 'src/packages/scss/varriable';

.full-width-height {
    display: flex;
    width: 100%;
    height: 100%;
    color: #667085;
}

.justify-between {
    display: flex;
    justify-content: space-between;
}

.ant-menu-submenu-title:has(.parent-selected) {
    color: #285FF6 !important;
    border-left: 3px solid #285FF6 !important;
    background-color: #e1f0e9 !important;
}
.ant-menu-title-content,
.ant-menu-title-content a {
    :hover {
        color: #285FF6 !important;
    }
}

.menu-wrapper {
    height: calc(100vh - 110px);
    overflow-y: auto;
    overflow-x: hidden;
}
.menu-wrapper::-webkit-scrollbar {
    width: 3px; /* You can adjust the width */
}

.menu-wrapper::-webkit-scrollbar-thumb {
    background-color: gray; /* Change this to the desired color */
}
