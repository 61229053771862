@import 'src/packages/scss/varriable';

.ksa-table-wrapper {
    height: calc(100vh - 470px);
    overflow-y: auto;

    .ant-table-wrapper table {
        font-weight: 500 !important;
        border-radius: 0;
    }

    .ant-table-thead .ant-table-cell {
        color: $text-color !important;
        font-weight: 500;
        font-size: 12px !important;
        background-color: #f9fafb;
        border-bottom: none;
        height: 44px;
        border: 0 !important;
        border-start-start-radius: 0 !important;
        border-start-end-radius: 0 !important;

        &:before {
            width: 0 !important;
        }
    }
    .ant-table-tbody > tr:hover > td {
        background: $gray-color-100 !important;
    }
    .ant-table-cell {
        padding: 12px 30px !important;
    }
    .status {
        display: inline-block;
        text-align: center;
        padding: 4px 10px;
        font-size: 14px;
        font-weight: 500;
        border-radius: $border-radius-4;

        &.failed {
            color: #b42318;
            background: #fef3f2;
        }

        &.success {
            color: $primary-color;
            background: $white-color;
        }
    }

    .ant-pagination {
        .ant-pagination-item-active {
            border-color: $primary-color;

            a {
                color: $primary-color !important;
            }
        }
    }
}

.table-auto-scroll-with-header {
    overflow-y: scroll;
    height: calc(100vh - 176px);
    thead {
        position: sticky !important;
        top: 0 !important;
        overflow: hidden;
        z-index: 100;
    }
}

.table-auto-scroll-without-header {
    overflow-y: auto;
    height: calc(100vh - 176px);

    * thead {
        position: sticky !important;
        top: 0 !important;
        overflow: hidden;
        z-index: 100;
    }
}
