@import 'src/packages/scss/varriable';

.drop-down-profile {
    width: 400px;
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: $border-radius-4;
    background-color: $white-color;
    padding: 20px;

    .profile-image {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        cursor: pointer;
    }

    .custom-divider {
        margin-top: 20px;
        margin-bottom: 20px;
        background-color: $gray-color-200;
    }

    .profile-details {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .user-name {
        margin: 10px;
        font-weight: 600;
        color: $title-color;
    }

    .user-handles {
        margin: 0 0 5px;
        font-weight: 500;
        font-size: 12px;
        color: $text-color;
    }

    .user-handles-email {
        margin: 0 0 5px;
        font-weight: 500;
        font-size: 12px;
        color: $text-color;
        text-align: center;
        white-space: normal;
        word-wrap: break-word;
        overflow-wrap: break-word;
        word-break: break-word;
        max-width: 100%;
        display: block;
    }

    .my-persona {
        margin-bottom: 14px;

        .my-persona-heading {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                font-weight: 600;
                font-size: 12px;
                color: $text-color;
            }

            .manage-button {
                font-weight: 600;
                font-size: 12px;
                border: none;
                color: $primary-color;
            }
        }
    }

    .persona-list-wrapper {
        .persona-scroller {
            height: 100px;
        }

        .see-more {
            margin-top: 14px;
            color: $primary-color;
            cursor: pointer;
        }

        .persona-list {
            display: flex;
            align-items: start;
            gap: 10px;

            .org-icon {
                margin-top: 5px;
            }

            .persona-details {
                cursor: pointer;
                margin-bottom: 10px;

                p {
                    margin: 0;
                }
            }
        }
    }

    .action-buttons {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 25px;

        .button-box {
            margin: 0;
            display: flex;
            gap: 11px;
        }

        .button-label {
            margin: 0;
            font-weight: 600;
            color: $text-color;
        }
    }
}