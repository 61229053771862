@import "src/packages/scss/varriable";

.core-set-password-wrapper {
  height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  align-items: center;

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }


  .core-set-password-wrapper-content {
    margin-top: -60px;
    width: 400px;
  }

  .core-set-password-sub-title {
    margin: 10px 0 40px;
    font-size: 16px;
  }

  .error-validation {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    .validation-message {
      margin: 10px 0;
      font-weight: 500;
      color: $gray-color-700;
    }
  }
}

.validation-header {
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 16px;
  color: #160F46; //not included in the variable file
}

.success-box {
  font-size: 16px;
  color: $gray-color-300;
}

.success-checkbox {
  font-size: 16px;
  color: $primary-color;
}

.core-sign-up-buttons {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;

  p {
    margin-top: 20px;
  }
}