@import 'src/packages/scss/varriable';

.subscribe-layout-wrapper {

  .subscribe-layout-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .subscribe-children {
    background-color: $white-color;
    height: calc(100vh - 75px);
    overflow-y: hidden;
    border-left: 1px solid $gray-color-200;
  }


  .logo-wrapper {
    padding-top: 45px;
  }


  .layout-header {
    height: 75px;
    padding: 0 30px;
    background-color: $white-color;
    border-left: 1px solid $gray-color-200;
    border-bottom: 1px solid $gray-color-200;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .action-group {
      display: flex;
      align-items: center;
    }

  }
}
