@import 'src/packages/scss/varriable';
@import 'src/packages/scss/responsive-mixin';

.main-layout-wrapper {
    .logo-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 72px;
        border-bottom: 1px solid $gray-color-200;
        margin-bottom: 20px;
    }

    .ant-layout-sider {
        background-color: $white-color;
        max-width: 250px !important;
        min-width: 0 !important;

        .ant-layout-sider-children {
            overflow-x: hidden;
            overflow-y: auto;

            .collapsed-button {
                position: absolute;
                top: 56px;
                right: -15px;
                z-index: 1;
            }
        }
    }

    .ant-layout-sider-collapsed {
        .user-sidebar-info {
            .image {
                width: 45px;
                height: 45px;
            }

            .core-title {
                font-size: 16px;
            }

            .email {
                word-wrap: break-word;
                font-size: 10px;
            }
        }
    }

    .line-wrapper {
        margin-bottom: 20px;
        height: 14px;
        text-align: center;

        .line {
            display: inline-block;
            width: 100%;
            height: 1px;
            background-color: $gray-color-200;
        }
    }

    .site-layout {
        .site-layout-navbar {
            border-bottom: 1px solid $gray-color-200;
            border-left: 1px solid $gray-color-200;
        }
    }

    .layout-content {
        background-color: $white-color;
        height: calc(100vh - 72px);
        border-left: 1px solid $gray-color-200;
    }

    .ant-layout-sider-children {
        display: flex;
        flex-direction: column;
    }

    .ant-layout-sider-collapsed {
        .ant-menu-submenu-title {
            width: 80px !important;

            .ant-menu-item-icon {
                display: flex;
                margin-top: 12px;
            }
        }

        .ant-menu-item {
            width: 80px !important;
        }

        .ant-menu-title-content {
            height: 40px;
        }

        .ant-menu .ant-menu-item .ant-menu-item-icon {
            display: flex;
            margin-top: 20px;
        }

        .core-button {
            text-align: center;
        }
    }

    .ant-menu {
        border: 0 !important;

        .ant-menu-item {
            height: 56px;
            width: 250px;
            font-weight: 600;
            color: $gray-color-500;
            border-left: 3px solid white;
            border-radius: 0;
            margin: 0 !important;
            .ant-menu-item-icon {
                height: 15px;
                width: 15px;
            }
        }

        .ant-menu-item-selected {
            color: $primary-color;
            border-left: 3px solid $primary-color;
        }

        .ant-menu-submenu-title {
            height: 40px;
            width: 250px;
            font-weight: 600;
            color: $gray-color-500;
            // border-left: 3px solid white;
            border-radius: 0;

            .ant-menu-item-icon {
                height: 15px;
                width: 15px;
            }
        }
    }

    .vertical-divider {
        width: 1px;
        height: 22px;
        background: $gray-color-200;
        margin: 0 10px;
    }
}

.create-button {
    margin-left: 16px;
}

.drop-down-profile-button {
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 600;

    .profile-image {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }

    .org-name {
        color: $text-color;
        display: flex;
        gap: 7px;

        @include responsive(md) {
            display: none;
        }

        .drop-down-icon {
            transition: transform 1s;
        }

        .drop-down-icon-clicked {
            transform: rotate(180deg);
            transition: transform 1s;
        }
    }
}

.info-icon {
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 10px;
    color: $gray-color-700;

    &:hover {
        border: 1px solid $gray-color-300;
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px $gray-color-100;
        border-radius: $border-radius-4;
    }
}

.bandage {
    margin-left: 8px;
    cursor: pointer;
    .notification-bar {
        font-size: 20px;
    }
}
