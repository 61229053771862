@import "src/packages/scss/varriable";

.list-wrapper {
  overflow-y: auto;
  height: calc(100vh - 340px);

  .list-item {
    border-bottom: 1px solid $gray-color-200;
    font-weight: 500;

    .list-item-left {
      padding: 12px 0;
    }

    .list-item-right {
      padding: 12px 0;
    }

    &:last-child {
      border-bottom: 1px solid transparent;
    }
  }

}