@import '../../../../assets/scss/main';
@import '../../../../packages/scss/varriable';

.revoke-certificate-wrapper {
    padding: 24px;

    .revoke-certificate-icon {
        width: 48px;
        height: 48px;
        background-color: #fef0c7;

        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-bottom: 20px;
    }

    .confirm-message {
        margin: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #667085;
    }

    .revoke-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;

        .revoke-button {
            width: 170px;
            height: 44px;
            border-radius: $border-radius-4;
            margin-top: 32px;

            font-weight: 600;
            font-size: 16px;
            line-height: 24px;

            &:hover {
                border: 1px solid $gray-color-300;
            }

            &.no {
                background-color: $white-color;
                color: $gray-color-700;
            }

            &.yes {
                background-color: #d92d20;
                color: $white-color;
            }
        }
    }
}
