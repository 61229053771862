.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner {
    min-width: 22px;
    height: 21px;
    line-height: 22px;
}
.ant-picker-dropdown .ant-picker-header button {
    line-height: 34px;
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-body {
    padding: 0 18px;
}

.ant-picker-dropdown .ant-picker-footer {
    line-height: 34px;
}

.ant-picker-dropdown .ant-picker-header-view {
    line-height: 34px;
}

.ant-picker-dropdown .ant-picker-cell {
    padding: 2px 0;
}
