@import 'src/packages/scss/varriable';

.left-tab-wrapper {
    margin-top: 30px;
    .ant-tabs-left {
        .ant-tabs-tab {
            background: $white-color;
            color: $gray-color-500;
            font-weight: 600;
            border: none;
            border-radius: $border-radius-4 !important;
            padding: 10px 16px !important;
            &.ant-tabs-tab-active {
                background: #cfebe2 !important;
            }
        }
    }
    .ant-tabs-content-holder {
        border-left: 0;
    }
}

.top-tab-wrapper {
    .ant-tabs {
        padding: 4px 20px 20px 20px;
    }
    .ant-tabs-top {
        .ant-tabs-nav-wrap {
            border-bottom: 1px solid $gray-color-200;
        }
        .ant-tabs-tab {
            background: $white-color;
            color: $gray-color-500;
            font-weight: 600;
            &.ant-tabs-tab-active {
                background: $white-color !important;
            }
        }
    }
}
