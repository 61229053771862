@import "src/packages/scss/varriable";

.general-datepicker {
  &.ant-picker {
    width: 100%;
    height: 44px !important;
    border-color: $gray-color-300 !important;
    border-radius: $border-radius-8 !important;
  }
  &.ant-picker-focused {
    box-shadow: none;
  }
  .ant-picker-active-bar {
    background: $primary-color;
  }

}