@import "src/packages/scss/varriable";

.core-status {
  display: inline-block;
  padding: 2px 10px;
  line-height: 24px;
  border-radius: $border-radius-4;
  font-size: 14px;

  &.basic {
    color: $gray-color-700;
    background: $gray-color-100;
  }

  &.success {
    color: #027a48;
    background: #ecfdf3;
  }

  &.failed {
    color: #b42318;
    background: #fef3f2;
  }

  &.inactive {
    background: #fffaeb;
    color: #B54709;
  }

  &.cleared {
    color: #027A48;
    background: #ECFDF3;
  }

  &.pending {
    color: $gray-color-500;
    background: $gray-color-200;
  }

  &.rejected {
    color: #B42318;
    background: #FEF3F2;
  }

  &.warning {
    color: orange;
    background: #FEF3F2;
  }
}