@import "src/packages/scss/varriable";
@import "src/packages/scss/responsive-mixin";
@import "src/assets/scss/main";

.core-login-layout-wrapper {
  width: 100%;

  .core-layout-content {
    height: 100vh;
    background-color: $white-color;

    .core-login-layout-logo {
      padding: 20px 41px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .core-login-layout-footer {
      position: fixed;
      padding: 20px 40px;
      left: 0;
      bottom: 0;

      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $text-color;
      }
    }
  }

  .core-layout-images {
    @include responsive(md) {
      display: none;
    }
    position: relative;
    background-color: $gray-color-300;

    .antd-responsive{
      width: 100%;
      height: auto;
    }

    .dot-image {
      position: absolute;
      opacity: 0.6;

      &.top {
        top: 0;
        right: 0;
      }

      &.bottom {
        bottom: 0;
        left: 0;
      }
    }

    .core-login-wrapper {
      height: 100vh;
      display: flex;
      flex-direction: row;
      justify-content: end;
      align-items: center;
    }
  }
}