.filter-header {
    display: flex;

    .filterDropDown {
        margin-left: 15px;
        max-width: 80px;
        max-width: 80px;
    }

    .submit-button {
        margin-left: 15px;
        max-width: 140px;
        min-width: 125px;
    }
}