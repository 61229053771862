@import 'src/packages/scss/varriable';

.ksa-alert {
    border-radius: $border-radius-4 !important;
    border: 0 !important;
    padding: 16px !important;
    color: $gray-color-700 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}
