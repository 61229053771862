@import 'src/packages/scss/varriable';

.language-selection {
    width: 100px;
    border: solid 1px $primary-color;
    border-radius: $border-radius-4;
}

.language-menu-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;

    .language-menu-tooltip {
        width: 12px;
        display: flex;
        align-items: end;
    }
}
