@import 'src/packages/scss/varriable';

.slider {
    position: fixed;
    width: 150vw;
    height: 5px;
    overflow-x: hidden;
    z-index: 10000;
    top: 0;
    left: 0;
}

.line {
    position: fixed;
    opacity: 0.4;
    background: $primary-color;
    width: 150vw;
    height: 5px;
    overflow-x: hidden;
    z-index: 100;
    top: 0;
}

.subline {
    position: fixed;
    background: $primary-color;
    overflow-x: hidden;
    z-index: 100;
    width: 150vw;
    height: 5px;
    top: 0;
}

.inc {
    animation: increase 2s infinite;
}

.dec {
    animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
    from {
        left: -5%;
        width: 5%;
    }

    to {
        left: 130%;
        width: 100%;
    }
}

@keyframes decrease {
    from {
        left: -80%;
        width: 80%;
    }

    to {
        left: 110%;
        width: 10%;
    }
}
