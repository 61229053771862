@import 'src/packages/scss/varriable';

.container {
    margin: 30px;
}

.margin-30 {
    margin: 30px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-bottom-0 {
    margin-bottom: 0 !important;
}
.margin-bottom-10 {
    margin-bottom: 10px !important;
}
.margin-bottom-30 {
    margin-bottom: 30px !important;
}

.pd-20 {
    padding: 20px;
}

.round-box-shadow {
    border: 1px solid $gray-color-200;
    box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1), 0 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: $border-radius-4;

    .space-for-search {
        .ksa-table-wrapper {
            margin-top: 20px;
        }

        .filter-actions {
            display: flex;
            flex-direction: row;

            .filter-dropdown {
                width: 100%;
            }
        }
    }
}

.dropdown-item {
    padding: 4px 8px;

    .text {
        display: inline-block;
        margin-left: 8px;
        font-size: 16px;
        color: $gray-color-900;
    }
}

.edit-table-icon {
    display: inline-block;
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}

.full-width {
    width: 100% !important;
}

.general-checkbox {
    font-weight: 500;
    color: $gray-color-700;

    .ant-checkbox-inner {
        width: 16px;
        height: 16px;
        border-radius: $border-radius-4;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
        width: 6px;
        height: 10px;
        position: absolute;
        top: 45%;
    }
}

.general-device-limit {
    display: flex;
    justify-content: center;

    .warning-label {
        font-size: 20px;
        color: #ff0000;
        font-weight: 600;
    }
}

.full-page-loader {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}