@import 'src/packages/scss/varriable';

.delete-modal-wrapper {
    text-align: center;

    .warning-img {
        width: 48px;
        height: auto;
    }
    .core-title {
        margin-top: 10px;
    }
    p {
        margin-top: 10px;
        margin-bottom: 30px;
    }
    .ant-modal-body {
        text-align: center;
    }
    .ant-modal-footer {
        display: flex;

        .ant-btn {
            width: 50%;
        }
    }
}
