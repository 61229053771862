.ksa-collapse {
    background: $white-color !important;
    .ant-collapse-header {
        padding: 14px 16px !important;
        .ant-collapse-header-text {
            font-size: 14px;
            font-weight: 500;
            color: $gray-color-700;
        }
    }
}
