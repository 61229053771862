//https://gist.github.com/yactouat/d575f387313250eb30d2781e52b87360
//https://getbootstrap.com/docs/5.0/layout/breakpoints/
@mixin responsive( $breakpoint ) {
  @if $breakpoint == xs {
    @media only screen and (max-width: 575.98px) {
      @content;
    }
  }
  @if $breakpoint == sm {
    @media only screen and (max-width: 767.98px) {
      @content;
    }
  }
  @if $breakpoint == md {
    @media only screen and (max-width: 1199.97px) {
      @content;
    }
  }
  @if $breakpoint == lg {
    @media only screen and (max-width: 1399.98px) {
      @content;
    }
  }
  @if $breakpoint == xl {
    @media only screen and (min-width: 1399.99px) {
      @content;
    }
  }
  @if $breakpoint == only-sm {
    @media only screen and (min-width: 575.99px) and (max-width: 767.98px) {
      @content;
    }
  }
  @if $breakpoint == only-md {
    @media only screen and (min-width: 767.99px) and (max-width: 1199.97px) {
      @content;
    }
  }
  @if $breakpoint == only-lg {
    @media only screen and (min-width: 1199.98px) and (max-width: 1399.98px) {
      @content;
    }
  }
  @if $breakpoint == only-xl {
    @media only screen and (min-width: 1399.99px) {
      @content;
    }
  }
  @if $breakpoint == only-2xl {
    @media only screen and (min-width: 1920px) {
      @content;
    }
  }
  @if $breakpoint == md-above {
    @media only screen and (min-width: 1199.97px) {
      @content;
    }
  }
}

@mixin hideScrollbarBrowserSupport {
  scrollbar-width: none; // For Firefox
  -ms-overflow-style: none; // For IE
  &::-webkit-scrollbar {
    // For Chrome and Safari
    display: none;
  }
}

//example of how to use the mixin in your SCSS code (inside a given selector)
// .myCLass {
//   @include responsive(smartphone-portrait) {
//   font-size: 15px;
//}
//}