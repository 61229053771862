@import 'src/packages/scss/varriable';
@import 'src/packages/scss/responsive-mixin';


.drawer-wrapper {
  display: none;

  @include responsive(md) {
    display: block;
  }

  .drawer-inner-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }


  .drawer-button {
    font-size: 25px;
    color: black;
    display: none;

    @include responsive(md) {
      display: block;
    }
  }
}

.drawer-action-group {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .drawer-notification {
    .badge {
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        margin: 0;
        font-weight: 600;
        color: $text-color;
      }
    }
  }

  .drawer-action-button {
    display: flex;
    align-items: center;
    gap: 8px;


    .drawer-action-button-link {
      font-weight: 600;
      color: $text-color;
    }
  }

}