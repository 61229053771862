@import 'lazySlider';
@import 'src/assets/fonts/inter/fonts';
@import 'src/packages/scss/varriable';
@import 'src/packages/scss/responsive-mixin';
@import 'general-class';
@import 'icon-button';
@import 'general-table';
@import 'general-input';
@import 'general-tab';
@import 'general-alert';
@import 'general-collapse';
@import 'general-search-filter';
@import 'general-date.scss';

body {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
