@import 'src/packages/scss/varriable';

.general-input-wrapper {
    margin-bottom: 20px;
}

.general-select-wrapper {
    margin-bottom: 15px;
}

.generic-radio-wrapper {
    display: flex;
    align-items: center;
}

.general-input {
    border-radius: $border-radius-4;
    width: 100%;
    height: 44px;
    border: 1px solid $gray-color-300;
    padding: 10px 14px;
    font-size: 16px;
    color: $black-color;

    &::placeholder {
        color: $gray-color-500;
    }
}

.general-input-number {
    width: 100%;
    border: 1px solid $gray-color-300;
    font-size: 16px;
    color: $black-color;

    &::placeholder {
        color: $gray-color-500;
    }
}

.general-label {
    display: block;
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 14px;
    color: $gray-color-700;
}

.general-select {
    border-radius: $border-radius-4;
    .ant-select-selector {
        border-radius: $border-radius-4;
        border: 1px solid $gray-color-300 !important;
        font-size: 16px;
        color: $black-color;
    }
}

.general-date-picker {
    border-radius: $border-radius-4;
    width: 100%;
    height: 44px;
    border: 1px solid $gray-color-300;
    padding: 10px 14px;

    .ant-picker-input {
        input {
            font-size: 16px;
            color: $black-color;
        }
    }
}

.error-message {
    color: red;
    display: inline-block;
    margin: 2px 0;
    display: block;
}

.right-side-submit-button {
    display: flex;
    justify-content: flex-end;
}

.ant-select-selector {
    input {
        font-size: 16px !important;
        color: $black-color !important;

        &::placeholder {
            color: $gray-color-500 !important;
        }
    }

    .ant-select-selection-placeholder {
        color: $gray-color-500 !important;
    }
}

// for all input placeholder
::placeholder {
    color: $gray-color-500 !important;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $gray-color-500 !important;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $gray-color-500 !important;
}

.required-field {
    color: red;
}

.ant-select-arrow-loading {
    color: black !important;
}

.generic-tooltip {
    margin-left: 2px;
    cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
.remove-dropdown::-webkit-outer-spin-button,
.remove-dropdown::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.remove-dropdown[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.create-new-button {
    color: $primary-color !important;
}

.required-border {
    border: 1px solid red !important;
    border-radius: $border-radius-4;
}
