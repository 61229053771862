@import "src/packages/scss/varriable";

.core-icon-button {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  &.round {
    width: 30px;
    height: 30px;
    color: $gray-color-500;
    border: 1px solid $gray-color-200;
    border-radius: 50%;
    line-height: 27px;
    font-size: 12px;
    background-color: $white-color;
  }
}