@import '../../../../packages/scss/varriable';

.organization-notification-wrapper {
  width: 355px;

  .notification-null {
    padding: 90px 0;
    text-align: center;

    .notification-null-details {
      margin-top: 20px;

      p {
        margin: 0;
        color: $text-color;
      }
    }
  }

  .notification-item {
    margin-bottom: 29px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .item-box {
      width: 375px;

      .date-span {
        font-size: 12px;
        //color: red;
      }
    }

    .notification-header {
      font-weight: 600;
      font-size: 16px;
      color: $text-color;
      margin: 0 0 4px;
    }

    .active-notification-header {
      font-weight: 600;
      font-size: 16px;
      color: $primary-color;
      margin: 0 0 4px;
    }

    .notification-body {
      margin: 0;
      color: $text-color;
    }

    .notification-circle {
      background-color: $primary-color;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }

  .notification-divider {
    background-color: $gray-color-200;
    margin-bottom: 20px;
  }

  .notification-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .action-button {
      border: none;
      margin: 0;
      text-align: center;
      font-weight: 600;
      color: $text-color;
      cursor: pointer;
    }
  }
}
