@import "src/packages/scss/varriable";

.core-login-wrapper {
  height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  align-items: center;

  .core-login-wrapper-content {
    margin-top: -60px;
    width: 400px;
  }

  .core-login-sub-title {
    margin: 10px 0 40px;
    font-size: 16px;
  }

  .forgot-password {
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $primary-color;
  }

  .core-login-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
  }

  .sso-login {
    margin: 12px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    gap: 10px;

    .ant-divider {
      background-color: $gray-color-200;
      margin: 0;
    }

    p {
      color: $gray-color-500;
      margin: 0;
    }

  }

  .core-signup-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    p {
      margin-top: 10px;
    }
  }

  .sign-in-link {
    margin: 0;
    cursor: pointer;
    font-weight: 600;
    color: $primary-color;
  }

}