@import "src/packages/scss/varriable";
@import "src/packages/scss/responsive-mixin";

.trail-panel {
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: normal !important;

  p {
    font-weight: 500;
    color: $text-color;
    margin: 0;
    @include responsive(md) {
      font-weight: 400;
      font-size: 12px;
    }
  }

  .upgrade-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    font-weight: 600;
    color: $primary-color;

    .plus-button {
      margin-top: 3px;
      margin-left: 10px;
    }
  }
}