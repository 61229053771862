//Color
$primary-color: #285FF6;
$primary-disable: #589CFF;
$primary-color-50: #E7F5F1;
$primary-color-100: #CFEBE2;
$title-color: #101828;
$text-color: #667085;
$error-color: #D92D20;
$gray-color-100: #F2F4F7;
$gray-color-200: #EAECF0;
$gray-color-300: #D0D5DD;
$gray-color-500: #667085;
$gray-color-600: #475467;
$gray-color-700: #344054;
$gray-color-800: #1D2939;
$gray-color-900: #101828;
$black-color: #000000;
$white-color: #ffffff;


$red-color-500: #F04438;
$red-color-100: #FEE4E2;

$yellow-color-500: #7A2E0E;
$yellow-color-100: #FEF0C7;

$orange-color-500: #F38645;
$orange-color-100: #FFEEE4;

$blue-color-500: #537FD2;
$blue-color-100: #E4EDFF;

$purple-color-500: #A75DCC;
$purple-color-100: #F8EAFF;

$pink-color-500: #DB4BA6;
$pink-color-100: #FFE4F5;

//Border radius
$border-radius-20: 20px;
$border-radius-8: 8px;
$border-radius-4: 4px;

// Font family
$font-family: 'Inter';