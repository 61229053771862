@import 'src/packages/scss/varriable';

.ks-only-icon-button {
    &.with-border {
        background: #f9fafb;
        border: 1px solid $gray-color-300 !important;
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: $border-radius-4;
        width: 44px;
        height: 44px;
        color: $gray-color-700; //changed here from 800 to 700 as it wasn't assigned in the variable file
    }
    &.ant-btn-default {
        font-size: 18px;
        border: none;
        &:hover {
            border: none;
            color: $primary-color !important;
        }
    }
}
