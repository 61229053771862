@import '../../../../../packages/scss/varriable';

.setup-authenticator-app-wrapper {
    padding: 30px;

    .setup-authenticator-app-inner-wrapper {
        width: 582px;

        .setup-authenticator-sub-title {
            color: #344054;
            margin-top: 6px;
            margin-bottom: 30px;
        }

        .setup-authenticator-verification-code {
            margin-top: 30px;
            .general-input {
                width: 230px;
            }
            .alert {
                margin-top: 10px;
                padding: 10px;
                max-width: 400px;
            }
        }

        .ant-qrcode {
            border: 1px solid $gray-color-300;
        }
        .qr-image {
            width: 200px;
            height: 200px;
            border: 1px solid #d0d5dd;
        }
    }
}
