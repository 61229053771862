@import "src/packages/scss/varriable";

.core-back-button {
  cursor: pointer;
  display: inline-block;
  height: 44px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 48px !important;
  line-height: 1rem;
  border: 1px solid $gray-color-300;

  &.ant-btn-text {
    color: $gray-color-700
  }

  &:hover {
    color: $primary-color !important;
    border-color: $primary-color !important;
  }
  .ant-btn-icon {
    .anticon {
      font-size: 10px;
    }
  }

  &.full-width {
    width: 100%;
  }
  &.is-not-round {
    border-radius: $border-radius-4;
  }
}