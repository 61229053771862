@import "src/packages/scss/varriable";

.core-recover-account-wrapper {
  height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  align-items: center;

  .core-recover-account-wrapper-content {
    margin-top: -60px;
    width: 400px;
  }

  .sub-title {
    margin: 10px 0 40px;
    font-size: 16px;
  }

  .core-recover-account-buttons {
    margin-top: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    p {
      margin: 0;
    }
  }

  .login-here {
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $primary-color;
  }

  .general-input-wrapper {
    margin-bottom: 30px;
  }

}