@import 'src/packages/scss/varriable';

.subscription-trail-modal-wrapper {
  text-align: center;

  .subscription-form-wrapper {
    text-align: left;
  }

  .warning-icon {
    color: red;
    font-size: 40px;
  }

  .center-img {
    width: 48px;
    height: auto;
  }

  .core-title {
    margin: 0;
  }

  p {
    margin-top: 0;
    margin-bottom: 24px;
  }

  .ant-modal-body {
    text-align: center;
  }

  .ant-modal-content {
    padding: 40px;
  }

  .ant-modal-footer {
    display: flex;

    .ant-btn {
      width: 50%;
    }
  }
}
