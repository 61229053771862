@import '../../../../../packages/scss/varriable';

.security-key-wrapper {
    padding: 30px;

    .recovery-codes-info {
        width: 667px;
        height: 56px;
        background: #e7f5f1;
        border-radius: $border-radius-4;
        padding: 18px;
        margin-bottom: 20px;

        .info-box {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #25a67e;
            display: flex;
            align-items: center;

            .info-box-information {
                p {
                    margin: 0;

                    span {
                        font-weight: 700;
                    }
                }
            }

            .anticon svg {
                display: inline-block;
                width: 20px;
                height: 20px;
            }
        }
    }

    .info-box div:first-child {
        margin-right: 22px;
    }

    .title-color {
        color: $gray-color-700;
    }

    .tow-fa-sub-title {
        margin-top: 6px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $gray-color-700;
    }

    .margin-top-6 {
        margin-top: 6px;
    }

    .security-key {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 30px;

        .security-key-icon {
            margin-right: 2px;
        }

        .security-key-details {
            margin-right: 26px;

            p {
                margin: 0;
            }

            .security-handles {
                margin-bottom: 4px;
                font-weight: 700;
                font-size: 16px;
                color: $gray-color-900;
            }

            .security-key-handles {
                font-weight: 400;
                font-size: 14px;
                color: $gray-color-500;
            }
        }

        .security-step-button {
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $white-color;
            border: 1px solid #d0d5dd;
            box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: $border-radius-4;

            span {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: $gray-color-700;
            }
        }
    }
}

.security-divider {
    width: 100%;
    margin-top: 0;
    margin-bottom: 24px;
    background-color: $gray-color-200;
}

.security-done-button {
    text-align: center;

    span {
        font-weight: 600;
        font-size: 14px;
        color: $white-color;
    }
}

.text-center {
    justify-content: center;
}
