@import 'src/packages/scss/varriable';
@import 'src/packages/scss/responsive-mixin';

.layout-header {
    height: 71px;
    padding: 0 30px;
    background-color: $white-color;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .action-group {
        display: flex;
        align-items: center;
        @include responsive(md) {
            display: none;
        }
    }
}
