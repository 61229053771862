@import 'src/packages/scss/varriable';

.drop-down-notification {
    width: 355px;
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: $border-radius-4;
    background-color: $white-color;
    padding: 20px;

    .ant-tabs-tab {
        color: $text-color !important;
    }

    .notification-panel {
        display: flex;

        .unread-icon {
            background-color: $primary-color;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            font-size: 10px;
            color: $white-color;

            display: flex;
            justify-content: center;
        }
    }

    .notification-type {
        margin: 0 0 8px;
        font-weight: 600;
        font-size: 18px;
        color: $text-color;
    }

    .info-element {
        margin: 0 0 20px;
        font-weight: 500;
        color: $gray-color-700;

        &.last {
            margin-bottom: 0;
        }
    }

    .info-nav-link {
        display: flex;
        flex-direction: column;
    }
}
